import React from 'react'
import loader from "../../assets/icons/loader.gif"

const Loading = () => {
    return (
        <div>
            <img className="loader" src={loader} alt="loader" onLoad />
        </div>
    )
}

export default Loading
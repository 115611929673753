import React from "react"
import "./Footer.scss"
import { Link, NavLink } from "react-router-dom"
// import Logo from "../../assets/imgs/logo.png"
import { FiMail } from "react-icons/fi"
import { BsChevronDoubleUp, BsTelephone } from "react-icons/bs"
import all from "../../assets/icons/all.png"
// import mastercard from "../../assets/icons/mastercard.png";
// import visa from "../../assets/icons/visa.png";

const Footer = () => {
  const yearNow = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__main">
          <Link to="/">
            {/* <img className="footer__logo" src={Logo} alt="logo" /> */}
            <p className="footer__logo">LUCCI Milano</p>
          </Link>

          <ul className="footer__list">
            <span>Contacts</span>
            <li>
              <a href="tel:+18189674388" className="footer__navlink">
                <BsTelephone className="footer__icon" />
                +(1818)-967-4388
              </a>
            </li>
            <li>
              <a
                href="mailto:ladyelitecorp@gmail.com"
                className="footer__navlink"
              >
                <FiMail className="footer__icon" />
                luccimilano@gmail.com
              </a>
            </li>
          </ul>

          {/* <ul className="footer__list">
            <span>Social Media</span>
            <li>
              <a
                href="https://www.facebook.com/LadyEliteUSA"
                target="_blank"
                rel="noreferrer"
                className="footer__navlink"
              >
                <FiFacebook className="footer__icon" />
                Lady Elite USA
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/ladyelite"
                target="_blank"
                rel="noreferrer"
                className="footer__navlink"
              >
                <FiInstagram className="footer__icon" />
                Lady Elite USA 🇺🇸
              </a>
            </li>
          </ul> */}

          <ul className="footer__list">
            <span>Main Pages</span>
            <li>
              <NavLink className="footer__navlink" to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className="footer__navlink" to="/about-us">
                About-us
              </NavLink>
            </li>
            <li>
              <NavLink className="footer__navlink" to="/contact-us">
                Contact-us
              </NavLink>
            </li>
          </ul>

          <ul className="footer__list">
            <span>Shop Pages</span>
            <li>
              <NavLink className="footer__navlink" to="/shop/shoes">
                Shoes
              </NavLink>
            </li>
            <li>
              <NavLink className="footer__navlink" to="/shop/dresses">
                Dresses
              </NavLink>
            </li>
            <li>
              <NavLink className="footer__navlink" to="/shop/blazers">
                Blazers
              </NavLink>
            </li>
            <li>
              <NavLink className="footer__navlink" to="/shop/tops">
                Tops
              </NavLink>
            </li>
            <li>
              <NavLink className="footer__navlink" to="/shop/leggings">
                Leggings
              </NavLink>
            </li>
            <li>
              <NavLink className="footer__navlink" to="/shop/suits">
                Suits
              </NavLink>
            </li>
            <li>
              <NavLink className="footer__navlink" to="/shop/accessories">
                Accessories
              </NavLink>
            </li>
            <li>
              <NavLink className="footer__navlink" to="/shop/bags">
                Bags
              </NavLink>
            </li>
          </ul>

          <button className="footer__btn-first" onClick={() => window.scrollTo(0, 0)}>
            Scroll To Top
            <BsChevronDoubleUp />
          </button>
        </div>

        <div className="footer__bottom">
          <img className="footer__all" src={all} alt="all" />

          <p className="footer__copyright">
            © {yearNow}. All Rights Reserved | By LUCCI Milano
            {/* <br/>
          Website designed and coded by "GS Development".  */}
          </p>

          <button className="footer__btn-second" onClick={() => window.scrollTo(0, 0)}>
            Scroll To Top
            <BsChevronDoubleUp />
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

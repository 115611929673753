import React, { Suspense } from "react"
import { useSessionState } from "../../hooks/useSessionState"
import { CartData } from "../../context/Context"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Header from "../header/Header"
import AutoScroll from "../../helpers/autoScroll"
import HelmetAsync from "./HelmetAsync"
import Footer from "../footer/Footer"
import Loading from "../loading/Loading"
const Home = React.lazy(() => import('../pages/home/Home'))
const AboutUs = React.lazy(() => import('../pages/about-us/AboutUs'))
const Shop = React.lazy(() => import('../pages/shop/Shop'))
const SubShop = React.lazy(() => import('../pages/shop/SubShop'))
const ContactUs = React.lazy(() => import('../pages/contact-us/ContactUs'))
const Cart = React.lazy(() => import('../pages/cart/Cart'))
const WishList = React.lazy(() => import('../pages/wishList/WishList'))
const NotFound = React.lazy(() => import('../pages/404/NotFound'))


const BrowserRouter = () => {
  var value = "";
  const [cart, setCart] = useSessionState([], "cart");
  const [list, setList] = useSessionState([], "wish");

  const handleAddToCart = (item) => {
    if (cart.some((cartItem) => cartItem.id === item.id)) {
      setCart((cart) =>
        cart.map((cartItem) =>
          cartItem.id === item.id
            ? {
              ...cartItem,
            }
            : cartItem
        )
      );
      return;
    }
    setCart((cart) => [...cart, item]);
  };

  const handleAddToWish = (item) => {
    if (list.some((listItem) => listItem.id === item.id)) {
      setList((list) =>
        list.map((listItem) =>
          listItem.id === item.id
            ? {
              ...listItem,
            }
            : listItem
        )
      );
      return;
    }
    setList((list) => [...list, item]);
  }

  const handleSelect = (e, item) => {
    value = e.target.value;

    const ind = cart.indexOf(item);
    const arr = cart;
    // arr[ind].selectedSize += d;

    arr[ind].selectedSize = value;
    setCart([...arr]);
  }

  const handleChanges = (item, d) => {
    const ind = cart.indexOf(item);
    const arr = cart;
    arr[ind].amount += d;

    if (arr[ind].amount === 0) arr[ind].amount = 1;
    setCart([...arr]);
  };

  return (
    <CartData.Provider value={cart}>
      <Router>
        <Header cartSize={cart.length} />
        <AutoScroll />
        <HelmetAsync />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route
              exact
              path="/shop/:id"
              element={
                <Shop
                  handleAddToWish={handleAddToWish}
                />
              }
            />
            <Route
              exact
              path="/shop/:id/:title"
              element={
                <SubShop
                  handleAddToCart={handleAddToCart}
                  handleAddToWish={handleAddToWish}
                />
              }
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/cart"
              element={
                <Cart
                  cart={cart}
                  setCart={setCart}
                  handleSelect={handleSelect}
                  handleChanges={handleChanges}
                />
              }
            />
            <Route
              path="/wishList"
              element={
                <WishList
                  list={list}
                  setList={setList}
                  handleAddToCart={handleAddToCart}
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </CartData.Provider>
  );
};

export default BrowserRouter;

import React, { useState, useEffect, useRef } from "react"
import "./Header.scss"
import { Link, NavLink, useLocation } from "react-router-dom"
// import Logo from "../../assets/imgs/logo.png"
import {
  BsChevronDown,
  BsArrowDownRight,
  BsTelephone,
  BsHeart,
  BsCart3,
} from "react-icons/bs"

const Header = ({ cartSize }) => {
  const { pathname } = useLocation();
  const headerRef = useRef();
  const [burger, setBurger] = useState("close");
  const [openBurger, setOpenBurger] = useState(false);

  const handleBurger = () => {
    setBurger(burger === "open" ? "close" : "open");
    setOpenBurger(!openBurger);
  };

  openBurger
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  useEffect(() => {
    setOpenBurger(false);
    setBurger("close");
  }, [pathname]);

  useEffect(() => {
    let prevScrollpos = window.scrollY;
    window.onscroll = function () {
      const currentScrollPos = window.scrollY;
      const header = headerRef.current;
      if (prevScrollpos > currentScrollPos) {
        header.style.top = "0";
      } else {
        header.style.top = "-61px";
      }
      prevScrollpos = currentScrollPos;
    };
  }, []);

  return (
    <header className="header" ref={headerRef}>
      <div className="container">
        <nav className="header__nav">
          {/* <Zoom> */}
          <Link to="/" onClick={pathname === "/" && window.scrollTo(0, 0)}>
            {/* <img className="header__logo" src={Logo} alt="logo" /> */}
            <p className="header__logo">LUCCI Milano</p>
          </Link>
          {/* </Zoom> */}

          {/* <Zoom right cascade> */}
          <ul
            className={`header__list ${openBurger ? "header__list-active" : ""
              }`}
          >
            <li>
              <NavLink end className="header__navlink" to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className="header__navlink" to="/about-us">
                About-us
              </NavLink>
            </li>
            <li className="shop">
              <div className="header__navlink">
                Shop
                <BsChevronDown />
              </div>
              {/* Arajin dropdown */}
              <ul className="header__first-dropdown">
                <li>
                  <NavLink className="header__navlink" to="/shop/shoes">
                    Shoes
                  </NavLink>
                </li>
                <li className="ready_to_wear">
                  <div className="header__navlink">
                    Ready to wear <BsArrowDownRight />
                  </div>
                  {/* Erkrord dropdown */}
                  <ul className="header__second-dropdown">
                    <li>
                      <NavLink className="header__navlink" to="/shop/dresses">
                        Dresses
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="header__navlink" to="/shop/blazers">
                        Blazers
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="header__navlink" to="/shop/tops">
                        Tops
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="header__navlink" to="/shop/leggings">
                        Leggings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="header__navlink" to="/shop/suits">
                        Suits
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink className="header__navlink" to="/shop/accessories">
                    Accessories
                  </NavLink>
                </li>
                <li>
                  <NavLink className="header__navlink" to="/shop/bags">
                    Bags
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="header__link">
              <NavLink className="header__navlink" to="/contact-us">
                Contact-us
              </NavLink>
            </li>
          </ul>
          {/* </Zoom> */}

          {/* <Zoom right cascade> */}
          <div className="header__icons">
            <a href="tel:+18189674388">
              <BsTelephone className="header__icon" />
            </a>
            <Link to="/wishlist">
              <BsHeart
                className="header__icon"

              />
            </Link>
            <Link to="/cart">
              <BsCart3
                className="header__icon"
              />
              <span style={{ position: "absolute", top: "7px", color: "black" }}>{cartSize}</span>
            </Link>
          </div>
          {/* </Zoom> */}

          <div className="header__burger" role="button" onClick={handleBurger}>
            <i className={burger}></i>
            <i className={burger}></i>
            <i className={burger}></i>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
